import axios from 'axios';
import React from 'react';
import Header from './Navigattion/Header';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import Swal from 'sweetalert2';
import { Translation } from 'react-i18next';
import i18next from 'i18next';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import '../../src/assets/MyStyle.css';
import Spinner from 'react-bootstrap/Spinner';
import { Seo } from '../helpers/Seo';
import '../../src/assets/custom.css';


class NewLead extends React.Component {
   //Init
    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(window.location.search);

        this.state = {
            step: 1,
            email: '',
            name: '',
            prenom: '',
            password: '',
            passwordConfirm: '',
            telephone: '',
            montant: '',
            frequence_remboursement: '',
            date_naissance: '',
            adresse: '',
            appartement: '',
            ville: '',
            code_postal: '',
            province: '',
            origine_revenu: '',
            autre_origine_revenu: '',
            nom_employeur: '',
            telephone_employeur: '',
            occupation: '',
            situation_emploi: '',
            date_embauche: '',
            date_prochaine_paie: '',
            nom_reference: '',
            telephone_reference: '',
            faillite: '',
            revenu_mensuel_brut: '',
            loyer_mensuel: '',
            montant_electricite_mensuel: '',
            montant_loc_auto: '',
            montant_achat_meuble: '',
            montant_autre_oblig: '',
            compte_conjoint: '',
            nom_compte_conjoint: '',
            isActive:false,
            isAccount:false,
            isOther:false,
            dob:'',
            sourcePret : urlParams.get('sourcePret'),
            toSubmit: false,
            loading: false,
            utm_source: '',
            utm_campaign: '',
            utm_medium: '',

            error_list: [],
            errors: {},
            isChecked:true,
        }
      }
      toggleLoader = () => {
        if(!this.state.loading){
          this.setState({loading: true})
        }else{
          this.setState({loading: false})
        }
     }
    getDate = (dob) =>{
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const today = new Date();
        const firstDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const secondDate = new Date(dob.$y, dob.$M, dob.$D);
        const diffDays = Math.round((firstDate - secondDate) / oneDay);
        return diffDays
    }
    nextStep = (e) =>{


        const {name, prenom, password, passwordConfirm, telephone, step, sourcePret, frequence_remboursement,montant,date_naissance, adresse, ville, code_postal, province, origine_revenu,nom_employeur, telephone_employeur, occupation, situation_emploi, date_embauche, date_prochaine_paie, nom_reference, telephone_reference, autre_origine_revenu, revenu_mensuel_brut, loyer_mensuel, compte_conjoint, nom_compte_conjoint} = this.state;
        let isValid = true;
       const errors = {}
       //Validation
        if(step == 1){
            if(name.trim().length < 2){
                errors.usernameLength = <Translation>{(t) => <span>{t("valid_required_1")}</span>}</Translation>;
                isValid = false
            }
            if(prenom.trim().length < 2){
                errors.userprenomLength = <Translation>{(t) => <span>{t("valid_required_1")}</span>}</Translation>;
                isValid = false
            }
            if(password.trim().length < 2){
                errors.userpasswordLength = <Translation>{(t) => <span>{t("valid_required_1")}</span>}</Translation>;
                isValid = false
            }
            if(passwordConfirm.trim().length < 2){
                errors.userpasswordConfirmLength = <Translation>{(t) => <span>{t("valid_required_1")}</span>}</Translation>;
                isValid = false
            }else{
                if(password != passwordConfirm){
                    errors.userpasswordConfirmLength = <Translation>{(t) => <span>{t("valid_required_8")}</span>}</Translation>;
                    isValid = false
                }
            }
            if(telephone.trim().length < 10){
                errors.userphone =  <Translation>{(t) => <span>{t("valid_required_2")}</span>}</Translation>;
                isValid = false
            }

            if(telephone.trim().length < 2){
                errors.userprovince =  <Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                isValid = false
            }

            if(!date_naissance){
                errors.userDateNaissance = <Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                isValid = false
            }else{
                var dob = date_naissance;
                var age = this.getDate(dob)
                if(age < 18){
                    errors.userDateNaissance = <Translation>{(t) => <span>{t("valid_required_4")}</span>}</Translation>;
                    isValid = false
                }
            }
        }
        //Next step
       this.setState({errors})

       if(isValid ==true && step != 4){
             this.setState({step: step + 1});
       }
       return isValid
    }
    continue = (e) =>{
        e.preventDefault();
        this.nextStep();
    }
    prevStep = () =>{
        const {step} = this.state;
        this.setState({
            step: step - 1
        });
    }
    retour = e =>{
        e.preventDefault();
        this.prevStep();
    }
    formatPhoneNumber = (value) => {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`;

    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value});
        this.formatPhoneNumber()
    }

    opensweetalert(){
        Swal.fire({
            html:
            '<hr><img src = https://i.postimg.cc/fL3PCGpr/logo-red-white.png height="60" alt="logo">' +
            '<br/><hr> <img src = https://i.postimg.cc/zBmwm43M/loading.gif alt="percent" height="150px"> ',
            imageHeight: 20,
            title: "<h2 style='color:#ef6409'>" +  "À la recherche d'une solution" + "</h2>",
            timer:3000,
            showConfirmButton: false,
            })
        //Swal.showLoading()
    }


    handleCheckboxChange = () => {
        this.setState((prevState) => ({
            isChecked: !prevState.isChecked,
        }));
    };

    saveMarketing = async (e) => {
        e.preventDefault();
        const res = await axios.post('https://gestionkronos.ca/api/email-checker', this.state)
        if(res.data.status === 200){
            this.setState({error_list: []})
            this.nextStep();
        }else{
            this.setState({
                error_list: res.data.validate_err,
            })
        }
    }

    saveLeads = async (e) => {
        e.preventDefault();
        const res = await axios.post('https://gestionkronos.ca/api/email-checker', this.state)
        if(res.data.status === 200){
            this.setState({error_list: []})
            const {prenom, name, telephone, password, passwordConfirm, province, date_naissance} = this.state;
            if(!prenom || !name || !telephone || !password || !passwordConfirm || !province|| !date_naissance){
                this.nextStep();
            }else{
                this.setState({toSubmit: true})
                //Call API in GK
                const res = await axios.post('https://gestionkronos.ca/api/store', this.state)
                if(res.data.status === 200) {
                    const user_id = res.data.user_id;
                    const id_pret = res.data.id_pret;
                    const compagnie = res.data.compagnie;

                    if (id_pret == null || id_pret == undefined) {
                        var url = compagnie
                    } else {
                        url = compagnie + "thank-you/" + id_pret
                    }

                    //Reload page
                    this.opensweetalert();
                    //Wait for few secs
                    setTimeout(() => {
                        window.location.href = url
                    }, 2500);
                }
            }
        }else{
                this.setState({
                    error_list: res.data.validate_err,
                })
            }
        }


    render() {
        const source = decodeURIComponent(document.cookie.match('(^|;)\\s*' + 'utm_source' + '\\s*=\\s*([^;]+)')?.pop() || '');
        const medium = decodeURIComponent(document.cookie.match('(^|;)\\s*' + 'utm_medium' + '\\s*=\\s*([^;]+)')?.pop() || '');
        const campaign = decodeURIComponent(document.cookie.match('(^|;)\\s*' + 'utm_campaign' + '\\s*=\\s*([^;]+)')?.pop() || '');
        if(source){
            this.state.utm_source = source
            this.state.utm_medium = medium
            this.state.utm_campaign = campaign
        }

        const {step} = this.state;
        const {email, name, prenom, telephone, errors} = this.state;
        var axios = require('axios');


        switch (step) {
            case 1:
            return(
                <div>
                    <Seo
                        title="Pret personnel 100 approuvé sans document | Clic Focus Quebec."
                        description="Win time and money. Make a single request to find the best personal loan rate in Quebec."
                        type="webapp"
                        name="APPLICATION FORM"
                    />
                    <Header />

                    <section id="contact" className="contact">
                        <div className="section-title">
                            <h2><Translation>{(t) => <span>{t("form_title")}</span>}</Translation></h2>
                            <p><Translation>{(t) => <span>{t("form_subtitle")}</span>}</Translation></p>
                        </div>
                        <div className="container" id="container" data-aos="fade-up">
                            <div className="row-6">
                                <div className="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch">
                                    <div className="php-email-form">
                                        <div className="row">
                                            {/* <button onClick={this.opensweetalert}>Open Success Sweetalert Popup</button> */}
                                            {/* Nom */}
                                            <div className="form-group col-md-12">
                                                <input type="text"
                                                       className={errors.usernameLength ? 'form-control invalid' : 'form-control'}
                                                       name="name" onChange={this.handleInput}
                                                       value={this.state.name}
                                                       placeholder={i18next.t('placeholder_1')} required/>
                                                <small className='text-danger'>{
                                                    <div>{errors.usernameLength}</div>}</small>
                                            </div>
                                            {/* Prénom */}
                                            <div className="form-group col-md-12">
                                                <input type="text"
                                                       className={errors.userprenomLength ? 'form-control invalid' : 'form-control'}
                                                       name="prenom" onChange={this.handleInput}
                                                       value={this.state.prenom}
                                                       placeholder={i18next.t('placeholder_2')} required/>
                                                <small className='text-danger'>{
                                                    <div>{errors.userprenomLength}</div>}</small>
                                            </div>
                                            {/* Courriel */}
                                            <div className="form-group col-md-12">
                                                <input type="text"
                                                       className={this.state.error_list.email ? 'form-control invalid' : 'form-control'}
                                                       onChange={this.handleInput} value={this.state.email}
                                                       name="email" placeholder={i18next.t('placeholder_3')}/>
                                                <small className='text-danger'>{this.state.error_list.email}</small>
                                            </div>
                                            {/* Téléphone */}
                                            <div className="form-group col-md-12">
                                                <PhoneInput
                                                    inputClass="form-group col-md-6"
                                                    className={errors.userphone ? 'invalid' : 'none'}
                                                    country={'ca'}
                                                    value={this.state.telephone}
                                                    name="telephone"
                                                    onlyCountries={['ca']}
                                                    onChange={telephone => this.setState({telephone})}
                                                    inputStyle={{
                                                        width: "100%"
                                                    }}
                                                    disableCountryCode={true}
                                                    placeholder={'(514) 333 3333'}
                                                />
                                                <small className='text-danger'>{
                                                    <div>{errors.userphone}</div>}</small>
                                            </div>
                                            {/* Mot de passe */}
                                            <div className="form-group col-md-6">
                                                <input type="password"
                                                       className={errors.userpasswordLength ? 'form-control invalid' : 'form-control'}
                                                       name="password" onChange={this.handleInput}
                                                       value={this.state.password}
                                                       placeholder={i18next.t('placeholder_20')} required/>
                                                <small className='text-danger'>{
                                                    <div>{errors.userpasswordLength}</div>}</small>
                                            </div>
                                            {/* Confirmation de Mot de passe */}
                                            <div className="form-group col-md-6">
                                                <input type="password"
                                                       className={errors.userpasswordConfirmLength ? 'form-control invalid' : 'form-control'}
                                                       name="passwordConfirm" onChange={this.handleInput}
                                                       value={this.state.passwordConfirm}
                                                       placeholder={i18next.t('placeholder_21')} required/>
                                                <small className='text-danger'>{
                                                    <div>{errors.userpasswordConfirmLength}</div>}</small>
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label htmlFor="name"><Translation>{(t) =>
                                                    <span>{t("birthDay")}</span>}</Translation>
                                                </label>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        className='custom-input form-control'
                                                        openTo="year"
                                                        views={['year', 'month', 'day']}
                                                        value={this.state.date_naissance}
                                                        onChange={date_naissance => this.setState({date_naissance})}
                                                        name="date_naissance"
                                                        placeholder="{i18next.t('birthDay')}"
                                                        maxDate='2006-01-01'
                                                        renderInput={(params) => <TextField {...params}
                                                                                            className={errors.userDateNaissance ? 'col-12 custom-input invalid' : 'col-12 custom-input'}
                                                        />}
                                                    />
                                                </LocalizationProvider>
                                                <small className='text-danger'>{
                                                    <div>{errors.userDateNaissance}</div>}</small>
                                            </div>
                                            {/* Province */}

                                            <div className="form-group col-md-6">
                                                <select className='form-select' aria-label="Default select example"
                                                        onSelect={this.handleInput} onChange={this.handleInput}
                                                        value={this.state.province} name="province">
                                                    <option value=""disabled={true}><Translation>{(t) =>
                                                        <span>{t("province")}</span>}</Translation></option>
                                                    <option value="QC">Québec</option>
                                                    <option value="ON">Ontario</option>
                                                    <option value="NS">Nouvelle-Écosse</option>
                                                    <option value="NB">Nouveau-Brunswick</option>
                                                    <option value="MB">Manitoba</option>
                                                    <option value="BC">Colombie-Britannique</option>
                                                    <option value="PE">Île-du-Prince-Édouard</option>
                                                    <option value="SK">Saskatchewan</option>
                                                    <option value="AB">Alberta</option>
                                                    <option value="NL">Terre-Neuve-et-Labrador</option>
                                                    <option value="YT">Yukon</option>
                                                    <option value="NT">Territoires du Nord-Ouest</option>
                                                    <option value="NU">Nunavut</option>
                                                </select>
                                                <small className='text-danger'>{
                                                    <div>{errors.userpasswordLength}</div>}</small>
                                            </div>
                                            <label>
                                                <input type="checkbox"
                                                       checked={this.state.isChecked}
                                                       onChange={this.handleCheckboxChange}/>
                                                J'accepte de recevoir des
                                                communication de Clic Focus
                                                <p><small><Translation>{(t) =>
                                                    <span>{t("p_consent")}</span>}</Translation></small></p>
                                            </label>


                                        </div>
                                        <div className="text-center">
                                            <button onClick={this.saveLeads} disabled={this.state.toSubmit}
                                                    className='btn btn-dark next'><Translation>{(t) =>
                                                <span>{t("send_form")}</span>}</Translation> <Spinner
                                                animation={this.state.toSubmit ? 'border' : null} variant="warning"
                                                size="sm"/></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
        }

    }
}

export default NewLead;

