
import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Translation } from 'react-i18next';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Link} from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import '../../../src/assets/Slider.css';

class Slider extends Component {
    render() {
        return (
    <div>
        <article className="carousel-holder">
            <div className="body_slider">
                <div className="container">
                    <p className="p_slider">Clic Focus</p>
                    <b>
                        <span1>
                            Bienvenue chez Clicfocus<br/>
                            Votre connexion<br/>
                            vers les meilleures offres<br/>
                            de prêts en ligne<br/>
                            Préapprobation en 1 minute!
                        </span1>
                    </b>
                    <br/>
                    <div>
                        <Link to={'/new-lead'} className="red"><Translation>{(t) =>
                            <span>{t("slider_button")}</span>}</Translation></Link>
                    </div>


                </div>

            </div>


            {/*<Carousel>*/}
            {/*  <Carousel.Item>*/}
            {/*    <section className="carousel-frame">*/}
            {/*      <img src="./assets/img/banner1.jpg" alt="First slide" width={'200%'} />*/}
            {/*      <Carousel.Caption>*/}
            {/*      <h2><Translation>{(t) => <span>{t("slider_title")}</span>}</Translation></h2>*/}
            {/*      <Link to={'/new-lead'} className="red"><Translation>{(t) => <span>{t("slider_button")}</span>}</Translation></Link>*/}

            {/*      <p className="silder-text"><Translation>{(t) => <span>{t("slider_text")}</span>}</Translation></p>*/}

            {/*      </Carousel.Caption>*/}
            {/*    </section>*/}
            {/*  </Carousel.Item>*/}
            {/*  <Carousel.Item>*/}
            {/*    <section className="carousel-frame">*/}
            {/*      <img src="./assets/img/banner2.jpg" alt="second slide" width={'100%'}height={'200%'} />*/}
            {/*      <Carousel.Caption>*/}
            {/*      <h2><Translation>{(t) => <span>{t("slider_title")}</span>}</Translation></h2>*/}
            {/*      <Link to={'/new-lead'} className="red"><Translation>{(t) => <span>{t("slider_button")}</span>}</Translation></Link>*/}
            {/*      <p><Translation>{(t) => <span>{t("slider_text")}</span>}</Translation></p>*/}

            {/*      </Carousel.Caption>*/}
            {/*    </section>*/}
            {/*  </Carousel.Item>*/}
            {/*  <Carousel.Item>*/}
            {/*    <section className="carousel-frame">*/}
            {/*      <img src="./assets/img/banner3.jpg" alt="Third slide" width={'100%'} height={'200%'} />*/}
            {/*      <Carousel.Caption>*/}
            {/*      <h2><Translation>{(t) => <span>{t("slider_title")}</span>}</Translation></h2>*/}
            {/*      <Link to={'/new-lead'} className="red"><Translation>{(t) => <span>{t("slider_button")}</span>}</Translation></Link>*/}
            {/*      <p><Translation>{(t) => <span>{t("slider_text")}</span>}</Translation></p>*/}

            {/*      </Carousel.Caption>*/}
            {/*    </section>*/}
            {/*  </Carousel.Item>*/}
            {/*</Carousel>*/}
        </article>

    </div>
        )
    }

}


export default Slider;