
import React, {Component} from 'react';
import { Translation } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Icon} from "@iconify/react";


class Fonctionnement extends Component {
    render() {
        return (
            <div>
              <section className="about">
                <div className="container" data-aos="fade-up">
                  <div className="row content">
                    <div className="col-lg-6">
                      <p>
                        <Translation>{(t) => <span>{t("work_text1")}</span>}</Translation>
                      </p>
                      <p><Translation>{(t) => <span>{t("work_text2")}</span>}</Translation></p>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0">
                      <h5><Translation>{(t) => <span>{t("work_text4")}</span>}</Translation></h5>
                      <ul>
                        <li><i className="fa fa-check-circle"></i><Translation>{(t) =>
                            <span>{t("work_text5")}</span>}</Translation></li>
                        <li><i className="fa fa-check-circle"></i> <Translation>{(t) =>
                            <span>{t("work_text6")}</span>}</Translation></li>
                        <li><i className="fa fa-check-circle"></i> <Translation>{(t) =>
                            <span>{t("work_text7")}</span>}</Translation></li>
                        <li><i className="fa fa-check-circle"></i> <Translation>{(t) =>
                            <span>{t("work_text8")}</span>}</Translation></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              <section id="section1" className="section1 section-bg">
                <div className="parallax">
                <div className="container" data-aos="fade-up">
                  <div className="row content">
                    <div className="section-title big-title">
                      <h3><Translation>{(t) => <span>{t("navbar_title2")}</span>}</Translation></h3>
                      <h2><Translation>{(t) => <span>{t("work_title")}</span>}</Translation> <strong>
                        <Translation>{(t) => <span>{t("work_title_bold")}</span>}</Translation></strong></h2>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3 text-center">
                      <div className="card m-2 card-no-background justify">
                      <div className="card-body">
                          <div className="iconfont-wrapper mb-2">
                            <Icon icon="tabler:list-check" width="80"/>
                          </div>
                          <h5 className="card-title"><Translation>{(t) => <span>{t("title_card_1")}</span>}</Translation>
                          </h5>
                          <p className="card-text">
                            <Translation>{(t) => <span>{t("text_card_1")}</span>}</Translation>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3 text-center">
                      <div className="card m-2 card-no-background justify">
                        <div className="card-body">
                          <div className="iconfont-wrapper mb-3">
                            <Icon icon="tabler:search" width="70"/>
                          </div>
                          <h5 className="card-title"><Translation>{(t) => <span>{t("title_card_2")}</span>}</Translation>
                          </h5>
                          <p className="card-text">
                            <Translation>{(t) => <span>{t("text_card_2")}</span>}</Translation>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3 text-center">
                      <div className="card m-2 card-no-background justify">
                        <div className="card-body">
                          <div className="tabler:checkbox">
                            <Icon icon="tabler:folder-check" width="80"/>
                          </div>
                          <h5 className="card-title"><Translation>{(t) => <span>{t("title_card_3")}</span>}</Translation>
                          </h5>
                          <p className="card-text">
                            <Translation>{(t) => <span>{t("text_card_3")}</span>}</Translation>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 text-center">
                      <div className="card m-2 card-no-background justify">
                        <div className="card-body">
                          <div className="iconfont-wrapper mb-2">
                            <Icon icon="tabler:clock-dollar" width="80"/>
                          </div>
                          <h5 className="card-title"><Translation>{(t) => <span>{t("title_card_4")}</span>}</Translation>
                          </h5>
                          <p className="card-text">
                            <Translation>{(t) => <span>{t("text_card_4")}</span>}</Translation>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </section>

              <section id="about" className="why-us section-bg">
                <div className="container-fluid" data-aos="fade-up">
                  <div className="row">
                    <div
                        className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                      <div className="content">
                        <h3><strong><Translation>{(t) => <span>{t("title_section_1")}</span>}</Translation></strong></h3>
                      </div>
                      <div className="accordion-list">
                        <p><Translation>{(t) => <span>{t("text_section_1")}</span>}</Translation></p>
                      </div>
                      <div className="content">
                        <h3><strong><Translation>{(t) => <span>{t("title_section_2")}</span>}</Translation></strong></h3>
                      </div>
                      <div className="accordion-list">
                        <p><Translation>{(t) => <span>{t("text_section_2_1")}</span>}</Translation> <Link style={{display: 'inline'}}
                            to={'/new-lead'}>ici</Link>
                          <br/>
                          <Translation>{(t) => <span>{t("text_section_2_2")}</span>}</Translation></p>
                      </div>
                    </div>
                    <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
                         style={{backgroundImage: 'url("assets/img/fonctionnement.jpg")', height:'400px', margin:'auto'}} data-aos="zoom-in"
                         data-aos-delay={150}>&nbsp;</div>
                  </div>
                </div>
              </section>


            </div>
        )
    }
}

export default Fonctionnement;